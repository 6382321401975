<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <div
              style="
                font-size: 14px;
                color: #b3b3b3;
                font-weight: 400;
                text-align: left;
                margin-top: 15px;
              "
            >
              *选择服务部门后，点击您需要的服务进行操作
            </div>
            <a-divider></a-divider>
          </a-col>
          <a-spin tip="Loading..." :spinning="sectionLoading">
            <a-col :span="24" v-if="sectionList.length <= 0">
              <a-empty description="请先选择服务区域" />
              <a-divider></a-divider>
            </a-col>
            <a-col :span="24" v-if="sectionList.length > 0">
              <a-row
                type="flex"
                justify="space-between"
                :gutter="[0, 40]"
                v-for="(item, index) in sectionList"
                :key="index"
              >
                <a-col
                  :span="24"
                  :lg="10"
                  v-for="(subItem, subIndex) in item"
                  :key="subIndex"
                  style="cursor: pointer"
                  @click="openSelectServiceBox(subItem)"
                >
                  <a-row :gutter="20">
                    <a-col :span="4">
                      <img
                        :src="subItem.icon"
                        style="width: 100%; height: auto"
                      />
                    </a-col>
                    <a-col :span="18" style="text-align: left">
                      <div
                        style="
                          font-size: 20px;
                          color: #313131;
                          font-weight: 500;
                        "
                      >
                        {{ subItem.name }}
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          color: #313131;
                          font-weight: 400;
                          margin-top: 5px;
                        "
                      >
                        <span style="color: #bbbbbb">服务：</span>
                        <span>{{ subItem.serviceName }}</span>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
                <a-divider></a-divider>
              </a-row>
              <div
                style="
                  font-size: 14px;
                  color: #b3b3b3;
                  font-weight: 400;
                  margin: 34px 0;
                "
              >
                * 选择以上任意部门为您提供相应的服务
              </div>
            </a-col>
          </a-spin>
        </a-row>
      </a-col>
    </a-row>
    <!-- 选择服务类型弹窗 -->
    <el-dialog
      title="选择服务类型"
      :visible.sync="serviceTypeDialog"
      :modal="true"
      @close="serviceTypeDialog = false"
      @opened="serviceTypeDialog = true"
      :modal-append-to-body="true"
      :append-to-body="true"
      :width="fullWidth > 992 ? '30%' : '90%'"
      center
    >
      <div class="special">
        <div
          class="special--item"
          v-for="(item, index) in roleList"
          :key="index"
          :style="{
            background: item.bgcolor,
          }"
          @click="gotoInspect(index)"
        >
          <div class="special--item--name">{{ item.name }}</div>
          <img class="special--item--icon" :src="$staticImg(item.icon)" />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="选择服务类型"
      :visible.sync="serviceNormalDialog"
      :modal="true"
      @close="serviceNormalDialog = false"
      @opened="serviceNormalDialog = true"
      :modal-append-to-body="true"
      :append-to-body="true"
      :width="fullWidth > 992 ? '30%' : '90%'"
      center
    >
      <div class="normal">
        <template v-if="!isEmpty(sectionDetail.id)">
          <div
            class="normal--item"
            v-for="(item, index) in sectionDetail.serviceTypeList"
            :key="index"
            @click="gotoNormal(index)"
          >
            <img class="normal--item--icon" :src="$staticImg(item.icon)" />
            <div class="normal--item--name">{{ item.name }}</div>
          </div>
        </template>
      </div>
    </el-dialog>

    <!-- 守未在线弹窗 -->
    <el-dialog
      title="守未在线"
      :visible.sync="onlineVisible"
      width="40%"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <!-- 守未在线工作室列表 -->
      <el-form
        ref="form"
        :model="studioList"
        label-width="200px"
        label-position:left
      >
        <div v-for="(item, index) in studioList" :key="index">
          <el-form-item :label="item.name">
            <img
              src="../../../public/images/guard-online.png"
              alt=""
              style="cursor: pointer"
              @click="handleMessage"
            />
          </el-form-item>
          <span>联系电话：{{ item.tel }}</span>
        </div>
      </el-form>
    </el-dialog>

    <!-- 服务须知 -->
    <el-dialog title="提示" :show-close="false" :visible.sync="dialogVisible" width="50%" :modal="true"
      :modal-append-to-body="true" :append-to-body="true">
      {{ tipText }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="handleSuccess" style="width: 320px">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      sectionList: [],
      addressOption: [],
      serviceArea: [],
      serviceTypeOption: [],
      submitObject: "single",
      reporter: "",
      reporterPhone: "",
      victim: "",
      victimPhone: "",
      reportStatus: "no",
      situation: "unknown",
      activeSection: "all",
      sortWay: "date", // date 时间排序 moods 人气排序
      rotate: 0,
      typeListStatus: 0, // 展开收起课程列表
      checkedTypeOption: {
        id: "all",
        name: "全部类型",
      },
      sectionLoading: false,
      visible: false,
      serviceDetailLoading: false,
      sectionDetail: {},
      serviceTypeDialog: false,
      serviceNormalDialog: false,

      onlineVisible: false,

      roleList: [
        {
          id: 1,
          icon: "/service/ic_service_konggao.png",
          name: "控告",
          type: 0,
          bgcolor: "linear-gradient(-33deg, #DFF1FA, #D8E0FC)",
          color: "#3374D9",
        },
        {
          id: 2,
          icon: "/service/ic_service_shensu.png",
          name: "申诉",
          type: 1,
          bgcolor: "linear-gradient(-33deg, #F9ECE1, #FCF5EF)",
          color: "#FF7C0F",
        },
        {
          id: 3,
          type: 2,
          icon: "/service/ic_service_sifajiuzu.png",
          name: "司法救助",
          bgcolor: "linear-gradient(-33deg, #EBDFFA, #EFE9FA)",
          color: "#8F54DC",
        },
        {
          id: 4,
          type: -2,
          icon: "/service/ic_service_gongyisusong.png",
          name: "公益诉讼",
          bgcolor: "linear-gradient(-33deg, #DFE6FA, #ECF0FC)",
          color: "#635DF7",
        },
        {
          id: 5,
          type: -1,
          icon: '/service/ic_service_shouweizaixian.png',
          name: '守未在线',
          bgcolor: 'linear-gradient(-33deg, #FAF3DF, #FAFCF4)',
          color: '#F49E2E'
        }
      ],

      // 守未在线工作室数据
      studioList: [
        { name: "守未联盟毕节检察工作室", tel: 110 },
        { name: "守未联盟七星关检察工作室", tel: 110 },
        { name: "守未联盟大方检察工作室", tel: 110 },
        { name: "守未联盟黔西检察工作室", tel: 110 },
        { name: "守未联盟金沙检察工作室", tel: 110 },
        { name: "守未联盟织金检察工作室", tel: 110 },
        { name: "守未联盟纳雍检察工作室", tel: 110 },
        { name: "守未联盟威宁检察工作室", tel: 110 },
        { name: "守未联盟赫章检察工作室", tel: 110 },
        { name: "守未联盟百里杜鹃检察工作室", tel: 110 },
      ],
      dialogVisible:false,
      tipText:'',
      objData:{},
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  mounted() {
    this.getSectionList();
  },
  methods: {
    ...mapMutations([]),

    // 点击检察部门
    gotoInspect(index) {
      if (index > 2) {
        if (index == 3) {
          console.log("section", this.sectionList);
          this.$router.push({
            path: "/serve/consult",
            query: {
              deptId: this.sectionList[0][0].id,
              deptName: this.sectionList[0][0].name,
              serviceId: "61b86364188d4f03301f692c",
              serviceName: "公益申诉",
            },
          });
          localStorage.setItem(
            "tipText",
            JSON.stringify(this.sectionList[0][0].tipText)
          );
        }
        // 点击守未在线服务时，弹出弹框
        if (index == 4) {
          this.onlineVisible = true;
          this.serviceTypeDialog = false;
        }
        return;
      }
      this.$router.push({
        path: "/serve/inspect",
        query: { type: index },
      });
      localStorage.setItem(
        "tipText",
        JSON.stringify(this.sectionList[0][0].tipText)
      );
    },
    // 点击其他部门
    gotoNormal(index) {
      this.$router.push({
        path: "/serve/consult",
        query: {
          deptId: this.sectionDetail.id,
          deptName: this.sectionDetail.name,
          serviceId: this.sectionDetail.serviceTypeList[index].id,
          serviceName: this.sectionDetail.serviceTypeList[index].name,
          // tipText: this.sectionDetail.tipText,
        },
      });
      localStorage.setItem(
        "tipText",
        JSON.stringify(this.sectionDetail.tipText)
      );
    },

    getSectionList() {
      this.sectionLoading = true;
      this.$HTTP
        .http({
          url: "/shouwei/department/list",
          method: "post",
          data: {},
        })
        .then((res) => {
          console.log("res :>> ", res);
          this.sectionLoading = false;
          if (res.code == 0) {
            let datas = res.data;
            if (datas.length > 0) {
              let arr1 = [];
              let arr2 = [];
              datas.forEach((v, i) => {
                arr1.push(v);
                if (arr1.length == 2) {
                  arr2.push(arr1);
                  arr1 = [];
                } else {
                  if (i == datas.length - 1) {
                    arr2.push(arr1);
                  }
                }
              });
              this.sectionList = arr2;
              let item = this.sectionList[0][0];
              item.serviceName = "控告,申诉,司法救助,公益诉讼,守未在线";
              this.sectionList[0].splice(0, 1, item);
              console.log("this.sectionList", this.sectionList);
            } else {
              this.sectionList = [];
              this.$elementMessage.info("暂无相关数据");
            }
          } else {
            this.$elementMessage.error("获取服务部门列表失败!");
          }
        });
    },

    openSelectServiceBox(value) {
      console.log("打开盒子", value);
      this.dialogVisible=true;
      this.tipText=value.tipText;
      this.objData=value;
      // if (value.opened == true) {
      //   if (value.name == "检察") {
      //     this.serviceTypeDialog = true;
      //   } else {
      //     this.sectionDetail = {};
      //     this.serviceNormalDialog = true;
      //     // 根据id查询服务详情
      //     this.getSectionDetail(value.id);
      //   }
      // } else {
      //   this.$elementMessage.info("功能待开发");
      // }
    },

    /**
     * 获取部门服务详情
     * @param {Object} id
     */
    getSectionDetail(id) {
      let data = {
        departmentId: id,
      };
      this.serviceDetailLoading = true;
      this.$HTTP
        .http({
          url: "/shouwei/department/detail",
          method: "post",
          data: data,
        })
        .then((res) => {
          console.log("服务详情", res);
          this.serviceDetailLoading = false;
          if (res.code == 0) {
            // 服务详情
            this.sectionDetail = res.data;
            console.log("this.sectionDetail :>> ", this.sectionDetail);
          } else {
            this.$elementMessage.error("获取服务详情失败！");
          }
        });
    },

    confirmChecked() {
      this.visible = false;
      this.$emit("switchComponent", "ConsultView");
    },

    // 点击守未在线留言图片
    handleMessage() {
      // 跳往留言页面
      this.$router.push({
        path: "/serve/consult",
        query: {
          deptId: this.sectionList[0][0].id,
          deptName: this.sectionList[0][0].name,
          serviceId: "61b862db188d4f03301f6920",
          serviceName: "守未在线",
        },
      });
      localStorage.setItem(
        "tipText",
        JSON.stringify(this.sectionList[0][0].tipText)
      );
    },

    // 点击服务须知确认按钮
    handleSuccess(){
      this.dialogVisible = false;
      console.log('this.ObjData :>> ', this.objData);
      if (this.objData.opened == true) {
        if (this.objData.name == "检察") {
          this.serviceTypeDialog = true;
        } else {
          this.sectionDetail = {};
          this.serviceNormalDialog = true;
          // 根据id查询服务详情
          this.getSectionDetail(this.objData.id);
        }
      } else {
        // this.$elementMessage.info("功能待开发");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.special {
  background: white;
  height: 250px;
  --w: calc(100% - 10px);

  &--item {
    width: calc(var(--w) / 2);
    height: 80px;
    border-radius: 16px;
    padding: 15px 12px 17px 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
    float: left;

    &--icon {
      width: 54px;
      height: 50px;
    }

    &--name {
      font-size: 14px;
      font-weight: bolder;
      position: absolute;
      right: 12px;
      bottom: 12px;
    }

    &:not(:nth-child(-n + 2)) {
      margin-top: 10px;
    }

    &:nth-child(2n) {
      margin-left: 10px;
    }
  }
}

.normal {
  background: white;
  height: 300px;

  &--item {
    width: 100%;
    height: 50px;
    border-radius: 16px;
    padding: 15px 12px 17px 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    border: 1px solid #e8e8e8;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;

    &--icon {
      width: 30px;
      height: 30px;
    }

    &--name {
      font-size: 14px;
      margin-left: 10px;
      font-weight: bolder;
    }
  }
}

.banner-img {
  overflow: hidden;
  position: relative;
}

.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.form-menu-active {
  /* display: inline-block; */
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  /* text-align: left; */
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}

.check-strip {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 6px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #0390d8, #51d2bd);
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #0e9bd2 !important;
}

.ant-tabs-ink-bar {
  background-color: #0e9bd2;
}

.tab-font {
  font-size: 14px;
  font-weight: bold;
}

.checked-service-item {
  background: linear-gradient(135deg, #51d2be, #0090d7);
  color: #ffffff;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 25px 25px 80px !important;
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

::v-deep .el-form-item__label {
  text-align: left !important;
}
::v-deep .el-form-item {
  margin-bottom: 0;
  height: 100%;
}

.el-form {
  margin-bottom: 10px;
  ::v-deep .el-form-item__content {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
